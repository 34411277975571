import React from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/layout/seo';
import Layout from '../components/layout/layout';
import SectionWrapper from '../components/layout/sectionWrapper';
import SectionContainer from '../components/layout/sectionContainer';
import SectionTitle from '../components/global/sectionTitle';
import {MdHelp, MdFileDownload} from 'react-icons/md';
import PortableText from '@sanity/block-content-to-react';
import {serializers} from '../utils/portableTextSerializers';
import CustomGatsbyImage from '../components/global/customGatsbyImage';
import {run} from "@tripetto/runner-classic";
import Services from "@tripetto/studio";

const Title = styled.h1`
  &:before {
    content: "";
    border-bottom: 2px solid #13F0BA;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  @media (max-width: 768px) {
    &:before {
      content: none;
    }
  }
`

const FAQ = styled.dl`
  ul li {
    font-size: 1rem;
    letter-spacing: 0.025em;
    line-height: 1.625;
  }

  p ~ p {
    margin-top: 1.5rem;
  }

  p {
    margin-bottom: 0;
  }

  ul {
    margin-top: 0.5rem;
  }
`;
const Documents = styled.div`
  h3:before {
    content:"•";
    color: #FDBF37;
    font-size: 1.5rem;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const useTripetto = () => {
  React.useEffect(() => {
    if (!Services) return;
    run(Services.get({
      element: document.getElementById("tripetto"),
      token: process.env.GATSBY_TRIPETTO_TOKEN,
      pausable: true,
      persistent: true,
      customCSS: `
        [data-block="tripetto-block-number"] input::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
        }
        [data-block="tripetto-block-number"] input::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
        }
        [data-block="tripetto-block-number"] input {
          -moz-appearance: textfield;
        }
        `,
    }));
  }, []);
};

const AppelAProjets = ({data}) => {
  const page = data.sanityPage.pageContent[0];
  useTripetto();
  return (
    <Layout>
      <SEO title="Appel à projets" />
      <SectionWrapper>
        <section className="relative flex flex-col items-center justify-center text-center h-72">
          <div className="absolute w-full h-full">
            <div className="absolute z-10 w-full h-full bg-black opacity-50" />
            <CustomGatsbyImage image={page.image} customClasses="h-full" />
          </div>
          <Title className="relative z-10 block text-3xl text-center text-white md:text-4xl">{page.title}</Title>
        </section>
        <SectionContainer customClasses="pt-16 md:pt-20 lg:pt-32">
          <div className="text-base leading-7 tracking-wide text-gray-700 whitespace-pre-line sm:text-lg">
            <p>{page.intro}</p>
          </div>
        </SectionContainer>
        <SectionContainer customClasses="pt-16 pb-20 md:py-20 lg:pb-20">
          <SectionTitle text={page.documentsTitle} />
          <Documents className="flex flex-col items-center justify-center w-full text-center">
            {page.documents.map((document, index) => (
              <div className="relative flex items-center w-full max-w-xl p-4 leading-none bg-white shadow-md" key={document.name}>
                <div className="flex flex-col items-start w-full pl-4">
                  <h3 className="relative text-left">{document.name}</h3>
                  <p className="mt-1 leading-normal text-left opacity-50 max-w-prose">{document.description}</p>
                </div>
                <a href={document.file.asset.url} className="ml-4 text-2xl"><MdFileDownload /></a>
              </div>
            ))}
          </Documents>
        </SectionContainer>
        <SectionContainer customClasses="pt-16 pb-20">
          <p className="flex flex-col items-center justify-center text-gray-700 whitespace-pre-line lg:flex-row"><MdHelp className="mb-8 text-5xl lg:mr-8 lg:mb-0 text-primary" />{page.additionalInfos}</p>
        </SectionContainer>
        <SectionContainer customClasses="pt-16 md:pt-20 lg:pt-32 pb-20 bg-white shadow-md">
          <div id="tripetto" />
        </SectionContainer>
        <SectionContainer customClasses="pt-16 pb-20 md:py-20 lg:pb-20">
          <SectionTitle text="Questions-réponses" />
          <FAQ className="grid grid-cols-1 gap-12 md:grid-cols-2">
            {page.faq.map((faqItem) => {
              return (
                <div className="flex flex-col" key={faqItem._key}>
                  <dt className="mb-2 text-lg font-bold">{faqItem.question}</dt>
                  <PortableText
                    blocks={faqItem._rawAnswer}
                    serializers={serializers} />
                </div>
              );
            })}
          </FAQ>
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  );
};

export default AppelAProjets;

export const query = graphql`
  query AppelAProjetPage {
    sanityPage(pageName: {eq: "Questionnaire"}) {
      pageContent {
        ... on SanityQuestionnairePageBlock {
          _key
          title
          image {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          documents {
            description
            file {
              asset {
                url
              }
            }
            name
          }
          documentsTitle
          intro
          additionalInfos
          faq {
            _key
            question
            _rawAnswer
          }
        }
      }
    }
  }
`;
